import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

function calculateOverlayAlpha(maxValue, currentValue) {
  const ratio = currentValue / maxValue;
  const alpha = Math.pow(ratio, 2) * 28;
  return alpha;
}

function MainRow6({ originOfUser, packageDownload }) {
  const [sortedData, setSortedData] = useState(packageDownload);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("package_name");

  const handleRequestSort = (property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setOrderDirection(isAscending ? "desc" : "asc");
    setValueToOrderBy(property);
    const sorted = [...packageDownload].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAscending ? 1 : -1;
      }
      if (a[property] > b[property]) {
        return isAscending ? -1 : 1;
      }
      return 0;
    });
    setSortedData(sorted);
  };

  useEffect(() => {
    handleRequestSort(valueToOrderBy);
  }, [packageDownload]);

  const uniquePackageNames = Array.from(
    new Set(packageDownload.map((pkg) => pkg.package_name))
  ).sort((a, b) => a.localeCompare(b));

  const [selectedPackage, setSelectedPackage] = useState(
    uniquePackageNames[0] || ""
  );
  const [downloadCounts, setDownloadCounts] = useState([]);

  const handlePackageChange = (event) => {
    setSelectedPackage(event.target.value);
  };

  function filterDownloadsByTime(downloads, fromDate) {
    return downloads.filter((download) => new Date(download.date) >= fromDate);
  }

  useEffect(() => {
    const computeDownloadCounts = () => {
      const now = Date.now();
      const timeFilters = [
        "1 Week",
        "1 Month",
        "3 Months",
        "6 Months",
        "1 Year",
        "TOTAL",
      ];
      const newDownloadCounts = timeFilters.map((filter) => {
        let fromDate;
        switch (filter) {
          case "1 Week":
            fromDate = new Date(now - 7 * 24 * 60 * 60 * 1000);
            break;
          case "1 Month":
            fromDate = new Date(now - 30 * 24 * 60 * 60 * 1000);
            break;
          case "3 Months":
            fromDate = new Date(now - 3 * 30 * 24 * 60 * 60 * 1000);
            break;
          case "6 Months":
            fromDate = new Date(now - 6 * 30 * 24 * 60 * 60 * 1000);
            break;
          case "1 Year":
            fromDate = new Date(now - 12 * 30 * 24 * 60 * 60 * 1000);
            break;
          case "TOTAL":
            fromDate = new Date(0);
            break;
        }
        return {
          time: filter,
          count: filterDownloadsByTime(
            packageDownload.filter(
              (pkg) => pkg.package_name === selectedPackage
            ),
            fromDate
          ).length,
        };
      });
      setDownloadCounts(newDownloadCounts);
    };

    computeDownloadCounts();
  }, [selectedPackage, packageDownload]);

  try {
    const sortedOriginOfUser = [...originOfUser].sort(
      (a, b) => b.count - a.count
    );

    const pieData = sortedOriginOfUser.map((item) => {
      return { name: item.Country, value: item.count };
    });

    const top10LegendData = pieData.slice(0, 10);

    const renderTooltipContent = ({ active, payload }) => {
      if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: "#cbe7f6",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <p style={{ color: "black", margin: 0 }}>{`${data.name}: ${
              data.value
            } (${(
              (data.value / (pieData[0].value + pieData[1].value)) *
              100
            ).toFixed(2)}%)`}</p>
          </div>
        );
      }
      return null;
    };

    const maxPieValue = Math.max(...pieData.map((item) => item.value));

    return (
      <Grid
        container
        spacing={3}
        paddingTop={5}
        style={{ marginTop: "-80px", marginBottom: "30px" }}
      >
        {/* Pie Chart */}
        <Grid item xs={4} style={{ height: "580px" }}>
          <Paper
            style={{
              height: "100%",
              textAlign: "center",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" align="center">
              Origin of Requests
            </Typography>
            <PieChart width={300} height={300}>
              <Pie
                data={pieData}
                dataKey="value"
                innerRadius={0}
                outerRadius={120}
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={`rgba(23, 136, 197, ${calculateOverlayAlpha(
                      maxPieValue,
                      entry.value
                    )})`}
                  />
                ))}
              </Pie>
              <Tooltip content={renderTooltipContent} />
              <Legend
                payload={top10LegendData.map((item, index) => ({
                  value: `${item.name} (${item.value})`,
                  type: "square",
                  id: item.name,
                  color: `rgba(23, 136, 197, ${calculateOverlayAlpha(
                    maxPieValue,
                    item.value
                  )})`,
                }))}
                verticalAlign="bottom"
                height={36}
                wrapperStyle={{ fontSize: "15px" }}
              />
            </PieChart>
          </Paper>
        </Grid>
        {/* Bar Chart */}
        <Grid item xs={4} style={{ height: "600px" }}>
          <Paper>
            <Typography
              variant="h6"
              align="center"
              style={{ marginBottom: "15px" }}
            >
              Download Frequency
            </Typography>
            <FormControl
              fullWidth
              style={{
                minWidth: 120,
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#fff",
                marginBottom: "65px",
              }}
            >
              <InputLabel style={{ color: "#000", fontSize: "0.875rem" }}>
                Data Package Name
              </InputLabel>
              <Select
                value={selectedPackage}
                onChange={handlePackageChange}
                label="Data Package Name"
                style={{
                  width: "100%",
                  fontSize: "0.875rem",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                {uniquePackageNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ResponsiveContainer width="98%" height={410}>
              <BarChart data={downloadCounts}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill="#4c89d8" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Data package download list */}
        <Grid item xs={4} style={{ height: "600px" }}>
          <Paper style={{ height: "100%", overflow: "auto" }}>
            <Typography
              variant="h6"
              style={{ textAlign: "center", padding: "10px" }}
            >
              Package Details
            </Typography>
            <TableContainer style={{ maxHeight: 540 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell key="package_name">
                      <TableSortLabel
                        active={valueToOrderBy === "package_name"}
                        direction={
                          valueToOrderBy === "package_name"
                            ? orderDirection
                            : "asc"
                        }
                        onClick={() => handleRequestSort("package_name")}
                      >
                        Package Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key="name">
                      <TableSortLabel
                        active={valueToOrderBy === "name"}
                        direction={
                          valueToOrderBy === "name" ? orderDirection : "asc"
                        }
                        onClick={() => handleRequestSort("name")}
                      >
                        User Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key="date">
                      <TableSortLabel
                        active={valueToOrderBy === "date"}
                        direction={
                          valueToOrderBy === "date" ? orderDirection : "asc"
                        }
                        onClick={() => handleRequestSort("date")}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.user_id + index}
                    >
                      <TableCell>{row.package_name}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {new Date(row.date).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    );
  } catch (error) {
    console.error(error);
    return <div>Error: {error.message}</div>;
  }
}

export default MainRow6;
