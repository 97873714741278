import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Autocomplete } from "@mui/material";
import { fetchInstIdList, fetchInstData } from "../../api";
import Row1 from "../charts/InstitutionActivityRow1";
import Row2 from "../charts/InstitutionActivityRow2";

function InstitutionInterestBox() {
  const [insts, setInsts] = useState([]);
  const [selectedInst, setSelectedInst] = useState(null);
  const [instData, setInstData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const instIds = await fetchInstIdList();
        const sortedInsts = instIds.sort((a, b) => a.name.localeCompare(b.name));
        setInsts(sortedInsts);
        if (sortedInsts && sortedInsts.length > 0) {
          setSelectedInst(sortedInsts[0]);
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchSelectedInstDetails = async () => {
      if (!selectedInst) return;
      try {
        const data = await fetchInstData(selectedInst.user_id);
        if (data.error) {
          setErrorMessage(data.error);
        } else {
          setInstData(data);
          setErrorMessage(null);
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    };
    fetchSelectedInstDetails();
  }, [selectedInst]);

  return (
    <Grid container spacing={3} style={{ width: "100%" }} justifyContent="center">
      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6" color={"#414291"} fontWeight={"Bold"} paddingBottom={2}>
          Institution Activity Dashboard
        </Typography>
        <Autocomplete
          options={insts}
          getOptionLabel={(option) => `${option.name} (${option.user_id})`}
          value={selectedInst}
          onChange={(event, newValue) => setSelectedInst(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Institution"
              variant="outlined"
              sx={{
                width: "250px",
                backgroundColor: "white",
              }}
            />
          )}
        />
      </Grid>
      {errorMessage ? (
        <Grid item xs={12}>
          <Typography variant="h4" color="error" align="center" fontWeight={"Bold"}>
            {errorMessage}
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Row1 data={instData} />
          </Grid>
          <Grid item xs={12}>
            <Row2 data={instData} />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default InstitutionInterestBox;
