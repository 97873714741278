import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Autocomplete } from "@mui/material";
import { fetchUserIdList, fetchUserData } from "../../api";
import Row1 from "../charts/UserInterestRow1";
import Row2 from "../charts/UserInterestRow2";
import Row3 from "../charts/UserInterestRow3";

function UserInterestBox() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userIds = await fetchUserIdList();
        const sortedUsers = userIds.sort((a, b) => a.name.localeCompare(b.name));
        setUsers(sortedUsers);
        if (sortedUsers && sortedUsers.length > 0) {
          setSelectedUser(sortedUsers[0]);
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchSelectedUserDetails = async () => {
      if (!selectedUser) return;
      try {
        const data = await fetchUserData(selectedUser.user_id);
        if (data.error) {
          setErrorMessage(data.error);
        } else {
          setUserData(data);
          setErrorMessage(null);
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    };
    fetchSelectedUserDetails();
  }, [selectedUser]);

  return (
    <Grid container spacing={3} style={{ width: "100%" }} justifyContent="center">
      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6" color={"#414291"} fontWeight={"Bold"} paddingBottom={2}>
          User Interest Dashboard
        </Typography>
        <Autocomplete
          options={users}
          getOptionLabel={(option) => `${option.name} (${option.user_id})`}
          value={selectedUser}
          onChange={(event, newValue) => setSelectedUser(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search User"
              variant="outlined"
              sx={{
                width: "250px",
                backgroundColor: "white",
              }}
            />
          )}
        />
      </Grid>
      {errorMessage ? (
        <Grid item xs={12}>
          <Typography variant="h4" color="error" align="center" fontWeight={"Bold"}>
            {errorMessage}
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Row1 data={userData} />
          </Grid>
          <Grid item xs={12}>
            <Row2 data={userData} />
          </Grid>
          <Grid item xs={12}>
            <Row3 data={userData} />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default UserInterestBox;
